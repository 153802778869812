<template>
  <router-view/>
</template>

<style>
#app {
  background-color: #e5e7eb;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
