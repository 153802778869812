<template>
    <pdf :src="pdfSrc" />
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: 'CV',
  components: {
    pdf
  },
  data () {
    return {
      pdfSrc: '/assets/cv/AlperBerber_CV.pdf'
    }
  }
}
</script>
