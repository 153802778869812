<template>
  <div id="Home">
    <div class="container-fluid">
      <div class="row">

        <div class="col-lg-4 offset-lg-3 col-sm-12">
          <div class="row">
            <div class="col-lg-12 mt-5 mb-1">
              <div class="about section-title  animate__animated animate__fadeInDown animate__faster">
                Hi, I am Alper!
              </div>

              <div class="about content">
                Hey, I am <em>Alper Berber</em>.
                <br>
                <strong>Backend Engineer</strong> @ <a class="link-a-like" href="https://www.bunq.com/" target="_blank" rel="noopener noreferrer"><em>bunq</em></a> 🌈  and <strong>Open-Source enthusiast</strong>.
                <br>
                <br>

                Greatly interested in <em><strong>Backend technologies</strong></em>, <em><strong>Software Design & Architecture</strong></em>.
                <br><br>
                You can preview my CV <a class="link-a-like" href="/assets/cv/AlperBerber_CV.pdf"><em>here <i class="fa-solid fa-arrow-up-right-from-square"></i></em></a>.
              </div>
            </div>

            <div class="col-lg-12 mt-4">
              <div class="social content">

                <ul>
                  <li><a href="https://twitter.com/alperdotberber" target="_blank"><em class="fab fa-twitter"></em></a></li>
                  <li><a href="https://github.com/alperb" target="_blank"><em class="fab fa-github"></em></a></li>
                  <li><a href="https://www.linkedin.com/in/alperberberr/" target="_blank"><em class="fab fa-linkedin-in"></em></a></li>
                  <!-- <li><a href="https://blog.alperb.com" target="_blank"><em class="fa-solid fa-blog"></em></a></li> -->
                </ul>

              </div>
            </div>

            <div class="col-lg-12 mt-1">
              <div class="about section-title">
                Some of my projects:
              </div>

              <div class="about content">
                <ul>
                  <li v-for="project in projects" :key="project.name">
                    <a target="_blank" :href="project.url">
                      {{ project.name }} <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </a> : {{ project.description }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data: () => {
    return {
      projects: [
        {
          name: 'crandom',
          description: 'A random number generator using circular equations to adjust probability distribution',
          url: 'https://github.com/tosuninc/crandom'
        },
        {
          name: 'su-sura',
          description: 'An automated schedule generator for Sabanci University',
          url: 'https://github.com/alperb/su-sura'
        },
        {
          name: 'odenne',
          description: 'A strongly typed battle simulation module written in Typescript',
          url: 'https://github.com/tosuninc/odenne'
        },
        {
          name: 'rdcp',
          description: 'Remote Daemon Control Protocol, a simple protocol for controlling daemons remotely',
          url: 'https://github.com/alperb/rdcp'
        },
        {
          name: 'uniter',
          description: 'A number millifier for converting long numbers to human readable, prettified format',
          url: 'https://github.com/tosuninc/uniter'
        },
        {
          name: 'dirgod',
          description: 'A simple tool for creating directory structures written in Go',
          url: 'https://github.com/alperb/dirgod'
        }
      ]
    }
  }
}
</script>

<style>
.my-image {
  width: 60%;
  margin: 0 20%;
  border-radius: 50%;
  margin-bottom: 20px;
}
.my-name {
  color: #212121;
  font-size: 1.3em;
  font-family: 'Roboto', sans-serif;
}

.about {
  font-family: 'Roboto', sans-serif;
}
.about.section-title{
  font-size: 2.2em;
  font-weight: bold;
  margin: 5vh 0;
}

.link-a-like {
  color: #212121;
}
.link-a-like:hover,
.link-a-like:active {
  color: #212121;
}

.social.content {
  font-size: 1.2em;
}

.social.content li a {
  color: #212121;
  font-size: 2em;
  margin: 0 10px;
}

.social.content li a:hover {
  color: #5C5C5C;
}

.social.content ul {
  list-style: none;
}

.social.content li {
  display: inline-block;
  margin: 0 10px;
}
</style>
